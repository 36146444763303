import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {Link} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import AuthProvider from "../Views/AuthProvider";
import {goToPage, updateDocumentTitle} from "../../Utils/Common";
import {useHistory} from "react-router-dom";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import DatePicker from "../Views/DatePicker";
import TimePicker from "../Views/TimePicker";

const Broadcasts = props => {

    let d = new Date();

    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [historyList, setHistoryList] = useState([]);
    const [scheduledList, setScheduledList] = useState([]);

    const [audience, setAudience] = useState('active_clients');
    const [commType, setCommType] = useState('sms');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [broadType, setBroadType] = useState('instant');
    const [broadDate, setBroadDate] = useState('');
    const [broadMonth, setBroadMonth] = useState('');
    const [broadYear, setBroadYear] = useState('');

    const [broadTime, setBroadTime] = useState('');
    const [broadMinute, setBroadMinute] = useState('');

    const [customAudience, setCustomAudience] = useState('');

    const columns = [
        {
            name: 'Audience',
            selector: 'audience'
        },
        {
            name: 'Medium',
            selector: 'communication_medium',
        },
        {
            name: 'Type',
            selector: 'broadcast_type',
        },
        {
            name: 'Time',
            cell: row => <div><span>{row.broadcast_date_string}</span></div>,
        },
        {
            name: 'Subject',
            cell: row => <div><span>{row.subject}</span></div>,
        },
        {
            name: 'Message',
            cell: row => <div><span>{row.message}</span></div>,
        },
        {
            name: 'Status',
            cell: row => <div><span
                style={{color: row.sent ? Colors.green_700 : Colors.red_700}}>{row.sent ? "SENT" : "PENDING"}</span>
            </div>,
        },
        {
            name: '',
            cell: row => row.sent ? '-' : <button className={"btn btn-sm btn-danger"}
                                                  onClick={e => deleteBroadcast(row.ID)}>Delete</button>,
        },
    ]


    useEffect(() => {
        updateDocumentTitle("Broadcasts")
        loadHistory()
        loadScheduledHistory()
    }, []);

    function deleteBroadcast(id) {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true)
        postData(Endpoints.DELETE_BROADCAST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    loadScheduledHistory()
                    loadHistory()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const submitForm = e => {
        e.preventDefault();

        let data = new FormData();
        data.append("audience", audience)
        data.append("comm_type", commType)
        data.append("subject", subject)
        data.append("message", message)
        data.append("custom_audience", customAudience)
        data.append("broadcast_time", broadTime)
        data.append("broadcast_minute", broadMinute)
        data.append("broadcast_date", broadDate)
        data.append("broadcast_month", broadMonth)
        data.append("broadcast_year", broadYear)
        data.append("broadcast_type", broadType)

        setIsLoading(true);

        postData(Endpoints.SEND_BROADCAST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setMessage('');
                    setSubject('');
                    if (broadType === "instant") {
                        loadHistory()
                    } else {
                        loadScheduledHistory()
                    }

                    Notify(res.data, SUCCESS);
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    function loadHistory() {
        let data = new FormData();
        data.append("sent", 1);
        setIsLoading(true)
        postData(Endpoints.LIST_BROADCAST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setHistoryList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadScheduledHistory() {
        let data = new FormData();
        setIsLoading(true)
        postData(Endpoints.LIST_SCHEDULED_BROADCAST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setScheduledList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Broadcasts
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabNew" role="tab" data-toggle="tab">
                                        Send a new broadcast message
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabScheduled" role="tab" data-toggle="tab">
                                        Scheduled pending broadcasts
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabPrevious" role="tab" data-toggle="tab">
                                        Sent broadcasts
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabNew">

                                <div className="card-block">
                                    <form onSubmit={submitForm}>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Communication
                                                        medium</label>
                                                    <select className="form-control"
                                                            value={commType}
                                                            onChange={({target}) => setCommType(target.value)}>
                                                        <option value={"sms"}>SMS</option>
                                                        <option value={"email"}>Email</option>
                                                        <option value={"push"}>Push notification</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Select
                                                        audience</label>
                                                    <select className="form-control"
                                                            value={audience}
                                                            onChange={({target}) => setAudience(target.value)}>

                                                        <option value={"active_clients"}>All active clients</option>
                                                        <option value={"inactive_clients"}>Inactive clients</option>
                                                        <option value={"active_riders"}>All active riders</option>
                                                        <option value={"inactive_riders"}>Inactive riders</option>
                                                        {commType !== "push" &&
                                                        <option value={"custom"}>Custom audience</option>
                                                        }
                                                    </select>
                                                </div>


                                                {audience === "custom" &&
                                                <div className="form-group">
                                                    <label
                                                        className="form-label col-form-label-sm">Separate {commType === "sms" ? "phone numbers" : "emails"} with
                                                        commas</label>
                                                    <textarea
                                                        rows={5}
                                                        placeholder={"Separate with commas"}
                                                        className="form-control"
                                                        value={customAudience}
                                                        onChange={e => setCustomAudience(e.target.value)}
                                                    />
                                                </div>
                                                }


                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Subject</label>
                                                    <input type="text"
                                                           placeholder={"Subject"}
                                                           className="form-control"
                                                           value={subject}
                                                           onChange={e => setSubject(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Message</label>
                                                    <div style={{fontStyle: 'italic', fontWeight: 500, fontSize: 13}}>
                                                        <p>{"{{name}} will be replaced with recipient's name. e.g Dear {{name}}"}</p>
                                                    </div>

                                                    <textarea
                                                        rows={5}
                                                        placeholder={"Message"}
                                                        className="form-control"
                                                        value={message}
                                                        onChange={e => setMessage(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Broadcast
                                                        type</label>
                                                    <select className="form-control"
                                                            value={broadType}
                                                            onChange={({target}) => setBroadType(target.value)}>
                                                        <option value={"instant"}>Instant</option>
                                                        <option value={"scheduled"}>Schedule</option>
                                                    </select>
                                                </div>

                                                {broadType === "scheduled" &&
                                                <div>
                                                    <DatePicker
                                                        onDateSelected={(pickerDate, pickerMonth, pickerYear) => {
                                                            setBroadDate(pickerDate)
                                                            setBroadMonth(pickerMonth)
                                                            setBroadYear(pickerYear)
                                                        }}/>

                                                    <TimePicker onTimeSelected={(pickerTime, pickerMinute) => {
                                                        console.log(pickerTime, pickerMinute)
                                                        setBroadTime(pickerTime)
                                                        setBroadMinute(pickerMinute)
                                                    }}/>
                                                </div>


                                                }


                                                <div className="form-group">

                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                        >{broadType === "scheduled" ? "Schedule" : "Send"} broadcast</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>
                                </div>

                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabPrevious">

                                <DataTable
                                    columns={columns}
                                    data={historyList}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabScheduled">

                                <DataTable
                                    columns={columns}
                                    data={scheduledList}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>

                        </div>
                    </section>

                </div>

            </div>
        </AuthProvider>
    )
};

export default Broadcasts;

