import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {updateDocumentTitle} from "../../Utils/Common";
import Map from "../Map/Map";

const RiderDetails = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({name: ''});
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [idNumber, setIDNumber] = useState('');
    const [vehicleRegNo, setVehicleRegNo] = useState('');
    const [areaID, setAreaID] = useState('');
    const [regionID, setRegionID] = useState('');
    const [regionList, setRegionList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [mapCentre, setMapCentre] = useState({});
    const [requestsList, setRequestList] = useState([]);


    const {id} = props.match.params;

    const load = () => {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true);
        postData(Endpoints.RIDER_DETAIL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setItem(res.data);
                    setName(res.data.name);
                    setPhone(res.data.phone);
                    setEmail(res.data.email);
                    setIDNumber(res.data.id_number);
                    setRegionID(res.data.region_id);
                    setAreaID(res.data.area_id);
                    setVehicleRegNo(res.data.reg_no)
                    setMapCentre({
                        lat: res.data.current_lat,
                        lng: res.data.current_lng
                    })
                    loadRegions(res.data.area_id)
                    updateDocumentTitle(res.data.name)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        load()
        loadAreas()
        loadRequests(false)
    }, []);

    function updateStatus(number) {
        let data = new FormData();
        data.append("id", id);
        data.append("status", number);

        setIsLoading(true);
        postData(Endpoints.RIDER_UPDATE_STATUS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(SUCCESS ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);

                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function updateDetails(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("id", id);
        data.append("name", name)
        data.append("email", email)
        data.append("phone", phone)
        data.append("id_number", idNumber)
        data.append("reg_no", vehicleRegNo)
        data.append("area_id", areaID)
        data.append("region_id", regionID)

        setIsLoading(true)
        postData(Endpoints.RIDER_UPDATE_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(SUCCESS ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadAreas() {
        let data = new FormData();
        data.append("status", 1);
        setIsLoading(true)
        postData(Endpoints.AREA_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setAreaList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadRegions(id) {
        let data = new FormData();
        data.append("area_id", id);
        data.append("status", 1);
        setIsLoading(true)
        postData(Endpoints.REGION_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRegionList(res.data || []);
                    if (res.data.length > 0) setRegionID(res.data[0].ID)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const loadRequests = (loadMore) => {
        let data = new FormData();
        data.append("id", id);
        data.append("offset", requestsList.length);

        setIsLoading(true);

        postData(Endpoints.REQUEST_LIST_RIDER, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? requestsList : [];
                currentPosts = currentPosts.concat(...res.data);
                setRequestList(currentPosts);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };


    return (
        <AuthProvider>
            {item.name !== '' &&
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>{item.name}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-center">
                                                    {isLoading &&
                                                    <ProgressView size={23} style={{color: Colors.black, margin: 10}}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    {!isLoading &&
                                                    <button type="submit"
                                                            className={item.status === 1 ? "btn btn-danger" : "btn btn-success"}
                                                            onClick={e => updateStatus(item.status === 1 ? 0 : 1)}>{item.status === 1 ? "Deactivate" : "Activate"}</button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to="/riders"
                                                          className="btn btn-success-outline">
                                                        Back to riders
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabDetails" role="tab" data-toggle="tab">
                                        Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRequests" role="tab" data-toggle="tab">
                                        Requests handled
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabReviews" role="tab" data-toggle="tab">
                                        Reviews
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabDetails">
                                <div className="row">
                                    <div className="col-md-7">
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td className="color-black-blue">
                                                    <b>{item.name}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Rider code</td>
                                                <td className="color-black-blue">
                                                    <b>{item.rider_code}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td className="color-black-blue">
                                                    <b>{item.email}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Phone</td>
                                                <td className="color-black-blue">
                                                    <b>{item.phone}</b></td>
                                            </tr>
                                            <tr>
                                                <td>ID Number</td>
                                                <td className="color-black-blue">
                                                    <b>{item.id_number}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Reg No</td>
                                                <td className="color-black-blue">
                                                    <b>{item.reg_no}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Completed tasks</td>
                                                <td className="color-black-blue">
                                                    <b>{item.completed_jobs}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Ongoing tasks</td>
                                                <td className="color-black-blue">
                                                    <b>{item.ongoing_jobs}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Cancelled tasks</td>
                                                <td className="color-black-blue">
                                                    <b>{item.cancelled_jobs}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Received jobs</td>
                                                <td className="color-black-blue">
                                                    <b>{item.received_jobs}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Rejected jobs</td>
                                                <td className="color-black-blue">
                                                    <b>{item.rejected_jobs}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.status === 0 ? "INACTIVE" : "ACTIVE"}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Registered on</td>
                                                <td className="color-black-blue">
                                                    <b>{item.date_added}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Current location</td>
                                                <td className="color-black-blue" style={{minWidth: 400}}>
                                                    <Map showCircle={true} mapCentre={mapCentre} radius={item.radius}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-5">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Update rider</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={updateDetails}>

                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Full
                                                            name</label>
                                                        <input type="text"
                                                               required
                                                               className="form-control"
                                                               value={name}
                                                               onChange={e => setName(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Email</label>
                                                        <input type="text"
                                                               required
                                                               className="form-control"
                                                               value={email}
                                                               onChange={e => setEmail(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Phone
                                                            number</label>
                                                        <input type="text"
                                                               required
                                                               className="form-control"
                                                               value={phone}
                                                               onChange={e => setPhone(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Vehicle
                                                            registration
                                                            number</label>
                                                        <input type="text"
                                                               required
                                                               className="form-control"
                                                               value={vehicleRegNo}
                                                               onChange={e => setVehicleRegNo(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Select
                                                            area</label>
                                                        <select className="form-control"
                                                                value={areaID}
                                                                onChange={({target}) => setAreaID(target.value)}>
                                                            {areaList.map((i, k) => <option
                                                                value={i.ID}>{i.title}</option>
                                                            )}
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Select
                                                            region</label>
                                                        <select className="form-control"
                                                                value={regionID}
                                                                onChange={({target}) => setRegionID(target.value)}>
                                                            {regionList.map((i, k) => <option
                                                                value={i.ID}>{i.title}</option>
                                                            )}
                                                        </select>
                                                    </div>


                                                    <div className="form-group">
                                                        <p><small>*Rider will be required to re-login on their
                                                            app</small></p>

                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Update</button>
                                                        }
                                                    </div>

                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabRequests">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table id="table" className="display table table-striped table-bordered"
                                               cellSpacing="0"
                                               width="100%">
                                            <thead>
                                            <tr>
                                                <th>Request</th>
                                                <th>Role</th>
                                                <th>Code</th>
                                                <th>Status</th>
                                                <th>Receiving Details</th>
                                                <th>Handing Over Details</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {requestsList.map((i, k) => <tr>
                                                    <td><Link to={"/request/" + i.ID}>#{i.request_code}</Link></td>
                                                    <td>{i.role}</td>
                                                    <td>{i.code}</td>
                                                    <td>{i.status}</td>
                                                    <td>
                                                        <b>Time</b> : {i.accepting_time_string}<br/>
                                                        <b>Location</b> : {i.accepting_location}<br/>
                                                        <b>LatLng</b> : {i.accepting_lat}, {i.accepting_lng}<br/><br/>
                                                        {i.accepting_signature === "" ? "-" : <img
                                                            src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + i.accepting_signature}
                                                            style={{height: 90}}/>}
                                                    </td>
                                                    <td>
                                                        {i.status !== "PENDING" &&
                                                        <div>
                                                            <b>Time</b> : {i.handing_time_string}<br/>
                                                            <b>Location</b> : {i.handing_location}<br/>
                                                            <b>LatLng</b> : {i.handing_lat}, {i.handing_lng}<br/><br/>
                                                            {i.handing_signature === "" ? "-" : <img
                                                                src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + i.handing_signature}
                                                                style={{height: 90}}/>}
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabReviews">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table id="table" className="display table table-striped table-bordered"
                                               cellSpacing="0"
                                               width="100%">
                                            <thead>
                                            <tr>
                                                <th>Request</th>
                                                <th>Role</th>
                                                <th>Code</th>
                                                <th>Rider review</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {requestsList.map((i, k) => <tr>
                                                    <td><Link to={"/request/" + i.ID}>#{i.request_code}</Link></td>
                                                    <td>{i.role}</td>
                                                    <td>{i.code}</td>
                                                    <td>
                                                        <div>
                                                            <b>Ratings</b> : {i.rider_rating} / 5<br/>
                                                            <b>Review</b> : {i.rider_review}<br/>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                </div>

            </div>
            }
        </AuthProvider>
    )
};


export default RiderDetails