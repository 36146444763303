import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import {updateDocumentTitle} from "../../Utils/Common";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";

const RequestsList = props => {
    let history = useHistory();

    const {id} = props.match.params;
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [activeList, setActiveList] = useState([]);

    const load = (loadMore) => {
        let data = new FormData();
        data.append("type", id);
        data.append("offset", activeList.length);

        setIsLoading(true);

        postData(Endpoints.REQUEST_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? activeList : [];
                currentPosts = currentPosts.concat(...res.data);
                setActiveList(currentPosts);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        updateDocumentTitle(id.toUpperCase())
        load(false);
    }, []);

    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>{id.toUpperCase()}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                {isLoading &&
                                                <ProgressView size={23}
                                                              style={{color: Colors.black, marginTop: 5}}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>


                <div className="container-fluid">
                    <section className="panel panel-default">
                        <div className="panel-body">
                            {activeList.map((i, k) =>
                                <div className="card-grid-col mb-4">
                                    <article className="card-typical">
                                        <div className="card-typical-section">
                                            <div className="user-card-row">
                                                <div className="tbl-row">
                                                    <div className="tbl-cell tbl-cell-photo">
                                                        <Link
                                                            to={"/request/" + i.ID}>
                                                            <img
                                                                src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + i.user_details.photo}
                                                                alt=""/>
                                                        </Link>
                                                    </div>
                                                    <div className="tbl-cell">
                                                            <span className="user-card-row-name"><b><Link
                                                                to={"/request/" + i.ID} style={{fontSize: 15}}>Pick up request at {i.pick_up_location}</Link></b></span>
                                                    </div>
                                                    <div className="tbl-cell tbl-cell-date">
                                                            <span className="semibold"
                                                            >#{i.request_code}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-typical-section card-typical-content">
                                            <div className="row mt-1">
                                                <div className="col-md-4">
                                                    <table className="table table-bordered table-hover table-sm">
                                                        <tbody>
                                                        <tr>
                                                            <td>Delivery Type</td>
                                                            <td className="color-black-blue">
                                                                <b>{i.delivery_type}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Means</td>
                                                            <td className="color-black-blue"><b>{i.means}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pick Up Type</td>
                                                            <td className="color-black-blue"><b>{i.pick_up_type}</b>
                                                            </td>
                                                        </tr>
                                                        {i.is_scheduled === 1 &&
                                                        <tr>
                                                            <td>Pick Up Time</td>
                                                            <td className="color-black-blue">
                                                                <b>{i.pick_up_date_time_string}</b></td>
                                                        </tr>
                                                        }

                                                        <tr>
                                                            <td>Fee</td>
                                                            <td className="color-black-blue"><b>KES {i.fee}</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Expected Completion Time</td>
                                                            <td className="color-black-blue">
                                                                <b>{i.expected_end_time_string}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Request Status</td>
                                                            <td className="color-black-blue"><b>{i.status}</b></td>
                                                        </tr>

                                                        {i.status === "CANCELLED" &&
                                                        <tr>
                                                            <td>Cancellation Reason</td>
                                                            <td className="color-black-blue">
                                                                <b>{i.cancellation_reason}</b>
                                                            </td>
                                                        </tr>
                                                        }

                                                        <tr>
                                                            <td>Progress Status</td>
                                                            <td className="color-black-blue">
                                                                <b>{i.progress_status}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Perf. Status</td>
                                                            <td className="color-black-blue">
                                                                <b>{i.performance_status}</b>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-md-8">

                                                    <table id="table-sm"
                                                           className="table table-bordered table-hover table-sm">
                                                        <thead>
                                                        <tr>
                                                            <th>Drop Off</th>
                                                            <th>Recipient</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {i.drop_offs.map((i, k) => <tr>
                                                            <td>{i.address}</td>
                                                            <td>{i.phone}</td>
                                                        </tr>)}

                                                        </tbody>
                                                    </table>

                                                </div>

                                            </div>
                                            <div className="form-group mt-3">
                                                <Link to={"/request/" + i.ID} className="btn btn-primary">
                                                    {i.status==="PENDING" && i.rider_id===0 ? "Assign Rider One" : "View Details"}
                                                </Link>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            )}
                        </div>

                    </section>
                </div>

            </div>
        </AuthProvider>
    )
};

export default RequestsList;

