import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import {updateDocumentTitle} from "../../Utils/Common";

const RidersList = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [activeList, setActiveList] = useState([]);
    const [inactiveList, setInactiveList] = useState([]);
    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            cell: row => <div><Link to={"/riders/" + row.ID}>{row.name}</Link></div>,
        },
        {
            name: 'Phone',
            selector: 'phone',
        },
        {
            name: 'Email',
            selector: 'email',
        },
        {
            name: 'ID Number',
            selector: 'id_number',
        }
    ]

    const load = (loadMore, status) => {
        let data = new FormData();
        data.append("status", status);
        setIsLoading(true);
        postData(Endpoints.RIDER_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (status === 0) {
                    let currentPosts = loadMore ? activeList : [];
                    currentPosts = currentPosts.concat(...res.data);
                    setActiveList(currentPosts);
                }

                if (status === 1) {
                    let currentPosts = loadMore ? inactiveList : [];
                    currentPosts = currentPosts.concat(...res.data);
                    setInactiveList(currentPosts);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        updateDocumentTitle("Riders")
        load(false, 1);
        load(false, 0);
    }, []);

    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Riders
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to="/riders/new"
                                                          className="btn btn-success">
                                                        Add new rider
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabActive" role="tab" data-toggle="tab">
                                        Active
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabInactive" role="tab" data-toggle="tab">
                                        Inactive
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabActive">

                                <DataTable
                                    columns={columns}
                                    data={inactiveList}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    onRowClicked={e => {
                                        history.push('/riders/' + e.ID);
                                    }}
                                    customStyles={Styles.customTableStyles}
                                />


                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabInactive">

                                <DataTable
                                    columns={columns}
                                    data={activeList}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    onRowClicked={e => {
                                        history.push('/riders/' + e.ID);
                                    }}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>


                        </div>
                    </section>

                </div>

            </div>
        </AuthProvider>
    )
};

export default RidersList;

