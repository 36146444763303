import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {Link} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import AuthProvider from "../Views/AuthProvider";
import {goToPage, updateDocumentTitle} from "../../Utils/Common";
import {useHistory} from "react-router-dom";

const NewArea = props => {

    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [centreLat, setCentreLat] = useState(0);
    const [centreLng, setCentreLng] = useState(0);
    const [radius, setRadius] = useState(10);

    useEffect(() => {
        updateDocumentTitle("Add new area")
    }, []);

    const submitForm = e => {
        e.preventDefault();

        let data = new FormData();
        data.append("name", name)
        data.append("centre_lat", centreLat)
        data.append("centre_lng", centreLng)
        data.append("radius", radius)

        setIsLoading(true);

        postData(Endpoints.AREA_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setName('');
                    Notify(res.data, SUCCESS);
                    goToPage(history, "/areas")
                } else {
                    if (Array.isArray(res.data)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Create a new area
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to="/areas"
                                                          className="btn btn-success-outline">
                                                        Back to areas
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabNew" role="tab" data-toggle="tab">
                                        Enter new areas details
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabNew">

                                <div className="card-block">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Name</label>
                                                    <input type="text"
                                                           required={true}
                                                           className="form-control"
                                                           placeholder="Name"
                                                           value={name}
                                                           onChange={e => setName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                 <label className="form-label col-form-label-sm">Centre
                                                        latitude</label>
                                                    <input type="text"
                                                           required={true}
                                                           className="form-control"
                                                           placeholder="Centre latitude"
                                                           value={centreLat}
                                                           onChange={e => setCentreLat(e.target.value)}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Centre
                                                        longitude</label>
                                                    <input type="text"
                                                           required={true}
                                                           className="form-control"
                                                           placeholder="Centre longitude"
                                                           value={centreLng}
                                                           onChange={e => setCentreLng(e.target.value)}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Radius in KM</label>
                                                    <input type="text"
                                                           required={true}
                                                           className="form-control"
                                                           placeholder="Radius"
                                                           value={radius}
                                                           onChange={e => setRadius(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                                onClick={e => submitForm(e)}>Create</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>
                                </div>

                            </div>


                        </div>
                    </section>

                </div>

            </div>
        </AuthProvider>
    )
};

export default NewArea;

