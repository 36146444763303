import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {clearItem, retrieveItem} from "../../Utils/Storage";
import {useGlobal} from "../../Store";
import Endpoints from "../../Constants/Endpoints";
import Dashboard from "../Dashboard";
import Login from "../Login";
import Settings from "../Settings/Settings";
import NewArea from "../Areas/New";
import AreaList from "../Areas/Listing";
import AreaDetails from "../Areas/Details";
import RegionDetails from "../Areas/RegionDetails";
import RidersList from "../Riders/Listing";
import NewRider from "../Riders/New";
import RiderDetails from "../Riders/Details";
import RequestOverview from "../Requests/Overview";
import {connect, sendMsg} from "../../Utils/WSocket";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import Popup from 'react-popup';
import {goToPage} from "../../Utils/Common";
import RequestsList from "../Requests/Listing";
import RequestDetails from "../Requests/Details";
import Receive from "../Requests/Receive";
import ClientList from "../Clients/Listing";
import ClientDetails from "../Clients/Details";
import BusinessList from "../Businesses/Listing";
import BusinessDetails from "../Businesses/Details";
import DepartmentDetails from "../Departments/Details";
import AccountDetails from "../Accounts/Details";
import LiveMap from "../Requests/LiveMap";
import Search from "../Search/Search";
import MeansTypeDetails from "../Areas/MeansTypeDetails";
import RequestTypeDetails from "../Areas/RequestTypeDetails";
import Broadcasts from "../Broadcasts/Broadcasts";
import Promo from "../Promo/Promo";
import ReverseTimer from "../Requests/ReverseTimer";


const Body = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();

    const checkUser = () => {
        let user = retrieveItem('user');
        if (user != null) {
            user = JSON.parse(user);
            let userLoggedIn = true;
            globalActions.setUserLoggedIn(userLoggedIn);
            globalActions.setCurrentUser(user);
        }
        globalActions.setUserCheckComplete(true)
    };

    const onCon = () => {
        Notify('Connected', SUCCESS)

        setInterval(() => {
            sendMsg("alive")
        }, 35000)
    }

    const onError = e => {
        Notify('Connection lost', ERROR)
    }

    const onClose = e => {
        Notify('Connection lost', ERROR)
    }

    const connectToSocket = () => {
        connect(onCon, onData, onClose, onError)
    }

    const onData = e => {
        let data = JSON.parse(e.data)
        switch (data.data_type) {
            case "new_request":
                Notify(data.body, SUCCESS, true)
                Popup.create({
                    title: 'New pick up request',
                    content: data.body,
                    buttons: {
                        left: [{
                            text: 'View requests',
                            className: 'success',
                            action: function () {
                                Popup.close();
                                goToPage(history, "/requests/incoming")
                            }
                        }],
                        right: [{
                            text: 'Cancel',
                            className: '',
                            action: function () {
                                Popup.close();
                            }
                        }]
                    }
                });
                break
        }
    }


    useEffect(() => {
        checkUser()
        connectToSocket()

        setTimeout(() => sendMsg("Hello"), 5000)
    }, []);

    return (
        <main>
            <Switch>
                <Route exact path='/dashboard' component={Dashboard}/>

                <Route exact path='/areas' component={AreaList}/>
                <Route exact path='/areas/new' component={NewArea}/>
                <Route exact path='/areas/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <AreaDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
                <Route exact path='/regions/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <RegionDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
                <Route exact path='/means/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <MeansTypeDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
                <Route exact path='/request_types/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <RequestTypeDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>

                <Route exact path='/riders' component={RidersList}/>
                <Route exact path='/riders/new' component={NewRider}/>
                <Route exact path='/riders/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <RiderDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>

                <Route exact path='/clients' component={ClientList}/>
                <Route exact path='/clients/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <ClientDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>

                <Route exact path='/businesses' component={BusinessList}/>
                <Route exact path='/business/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <BusinessDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
                <Route exact path='/department/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <DepartmentDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
                  <Route exact path='/account/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <AccountDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>

                <Route exact path='/requests' component={RequestOverview}/>
                <Route exact path='/requests/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <RequestsList
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
                <Route exact path='/request/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <RequestDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
                <Route exact path='/receive-goods' component={Receive}/>
                <Route exact path='/live-map' component={LiveMap}/>
                <Route exact path='/search' component={Search}/>

                <Route exact path='/settings' component={Settings}/>
                <Route exact path='/broadcasts' component={Broadcasts}/>
                <Route exact path='/promotions' component={Promo}/>
                <Route exact path='/reverse-timer' component={ReverseTimer}/>

                <Route exact path='/' component={Login}/>
            </Switch>
        </main>
    )

};

export default Body;
