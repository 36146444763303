import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import {updateDocumentTitle} from "../../Utils/Common";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";


const ReverseTimer = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [activeList, setActiveList] = useState([]);
    const [lastUpdated, setLastUpdated] = useState('');
    let lastLoaded = new Date();

    const columns = [
        {
            name: 'Overview',
            sortable: true,
            cell: row => <span style={{fontSize: 14}}>#{row.request_code}</span>,
        },
        {
            name: 'Pick Up',
            cell: row => <span style={{fontSize: 14}}>{row.pick_up_location}</span>,
        },
        {
            name: 'Start Time',
            selector: 'start_time_string',
            cell: row => <span style={{fontSize: 14}}>{row.start_time_string}</span>,
        },
        {
            name: 'Status',
            selector: 'status',
            cell: row => <span style={{fontSize: 14}}>{row.status}</span>,
        },
        {
            name: 'Expected End Time',
            selector: 'expected_end_time_string',
            cell: row => <span style={{fontSize: 14}}>{row.expected_end_time_string}</span>,
        },
        {
            name: '',
            cell: row => <div style={{
                display: 'flex',
                backgroundColor: getBG(row.performance_status),
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <span style={{fontWeight: 700, fontSize: 19, color: Colors.white}}>{row.performance_status}</span>
            </div>,
        }
    ]

    const getBG = status => {
        switch (status) {
            case "LATE":
                return Colors.red_700
            case "NEARLY LATE":
                return Colors.grey_400
            case "ON TIME":
                return Colors.green_700
        }
    }

    const getTimeDifference = () => {
        let now = new Date();
        let diffMs = (now - lastLoaded); // milliseconds between now & Christmas
        let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
        lastLoaded = new Date()
        setLastUpdated(`${diffMins} mins ago`)
    }

    const load = (loadMore) => {
        getTimeDifference()
        let data = new FormData();
        setIsLoading(true);
        postData(Endpoints.REQUEST_TIMER, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? activeList : [];
                currentPosts = currentPosts.concat(...res.data);
                setActiveList(currentPosts);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        updateDocumentTitle("Request Timer")

        setInterval(() => {
            load(false)
        }, 60000)

        load(false)
    }, []);

    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Requests Timer
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell text-center">
                                                <h6 style={{fontWeight: 600, color: Colors.red_600}}>Last
                                                    updated {lastUpdated}</h6>
                                                {isLoading &&
                                                <ProgressView size={23}
                                                              style={{color: Colors.black, marginTop: 5}}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <button onClick={e => load(false)}
                                                            className="btn btn-info">
                                                        Reload
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabActive" role="tab" data-toggle="tab">
                                        List of ongoing requests starting from nearest to completion
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabActive">

                                <DataTable
                                    columns={columns}
                                    data={activeList}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    onRowClicked={e => {
                                        history.push('/request/' + e.ID);
                                    }}
                                    customStyles={Styles.customTableStyles}
                                />


                            </div>

                        </div>
                    </section>

                </div>

            </div>
        </AuthProvider>
    )
};

export default ReverseTimer;

