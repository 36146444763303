import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {updateDocumentTitle} from "../../Utils/Common";

const AccountDetails = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({name: ''});

    const [requestsListOngoing, setRequestListOngoing] = useState([]);
    const [requestsListAll, setRequestListAll] = useState([]);
    const [businessList, setBusinessList] = useState([]);

    const [ratesList, setRatesList] = useState([]);
    const [requestType, setRequestType] = useState('');
    const [meansType, setMeansType] = useState('');
    const [peakRate, setPeakRate] = useState('');
    const [normalRate, setNormalRate] = useState('');

    const [requestTypeList, setRequestTypeList] = useState([]);
    const [meansTypeList, setMeansTypeList] = useState([]);

    const columns = [
        {
            name: '#',
            sortable: true,
            cell: row => <div><Link to={"/request/" + row.ID}>{row.request_code}</Link></div>,
        },
        {
            name: 'Type',
            selector: 'pick_up_type',
            sortable: true,
        },
        {
            name: 'Pick up',
            selector: 'pick_up_location',
            sortable: true,
        },
        {
            name: 'Delivery type',
            selector: 'delivery_type',
            sortable: true,
        },
        {
            name: 'Means',
            selector: 'means',
            sortable: true,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
        },
        {
            name: 'Fee',
            selector: 'fee',
            sortable: true,
        }
    ]

    const ratesColumns = [
        {
            name: 'Request Type',
            selector: 'request_type',
            sortable: true,
        },
        {
            name: 'Means Type',
            selector: 'means_type',
            sortable: true,
        },
        {
            name: 'Normal Rate',
            selector: 'normal_rate',
            sortable: true,
        },
        {
            name: 'Peak Rate',
            selector: 'peak_rate',
            sortable: true,
        },
        {
            name: '',
            cell: row => <button className={"btn btn-sm btn-danger"}
                                 onClick={e => deleteRate(row.ID)}>Delete</button>,
        },
    ]

    const {id} = props.match.params;

    const load = () => {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true);
        postData(Endpoints.CHILD_ACCOUNT_DETAIL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setItem(res.data);
                    updateDocumentTitle(res.data.email)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        load()
        loadRequests(false, "business")
        loadRates()
        loadMeansTypes()
        loadRequestTypes()
    }, []);

    function updateStatus(number) {
        let data = new FormData();
        data.append("id", id);
        data.append("status", number);

        setIsLoading(true);
        postData(Endpoints.CHILD_ACCOUNT_UPDATE_STATUS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(SUCCESS ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);

                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const loadRequests = (loadMore, status) => {
        let data = new FormData();
        data.append("child_account_id", id);
        data.append("status", status);
        data.append("offset", requestsListOngoing.length);

        setIsLoading(true);

        postData(Endpoints.REQUEST_LIST_USER, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? requestsListOngoing : [];
                currentPosts = currentPosts.concat(...res.data);
                setRequestListOngoing(currentPosts);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    function loadRates() {
        let data = new FormData();
        data.append("user_id", id);
        setIsLoading(true)
        postData(Endpoints.RATE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRatesList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function addRates(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("user_id", id);
        data.append("request_type", requestType)
        data.append("means_type", meansType)
        data.append("normal_rate", normalRate)
        data.append("peak_rate", peakRate)

        setIsLoading(true)
        postData(Endpoints.RATE_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    setNormalRate('')
                    setPeakRate('')
                    loadRates()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    function deleteRate(id) {

        let data = new FormData();
        data.append("id", id);

        setIsLoading(true)
        postData(Endpoints.RATE_DELETE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    loadRates()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadRequestTypes() {
        let data = new FormData();
        setIsLoading(true)
        postData(Endpoints.REQUEST_TYPE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRequestTypeList(res.data || []);
                    if (res.data.length > 0) setRequestType(res.data[0].name)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadMeansTypes() {
        let data = new FormData();
        setIsLoading(true)
        postData(Endpoints.MEANS_TYPE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setMeansTypeList(res.data || []);
                    if (res.data.length > 0) setMeansType(res.data[0].name)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    return (
        <AuthProvider>
            {item.name !== '' &&
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>{item.email}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-center">
                                                    {isLoading &&
                                                    <ProgressView size={23} style={{color: Colors.black, margin: 10}}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    {!isLoading &&
                                                    <button type="submit"
                                                            className={item.admin_active === 1 ? "btn btn-danger" : "btn btn-success"}
                                                            onClick={e => updateStatus(item.admin_active === 1 ? 0 : 1)}>{item.admin_active === 1 ? "Deactivate" : "Activate"}</button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to={"/department/" + item.department_id}
                                                          className="btn btn-success-outline">
                                                        Back to department
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabDetails" role="tab" data-toggle="tab">
                                        Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRequests" role="tab" data-toggle="tab">
                                        Requests made
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRates" role="tab" data-toggle="tab">
                                        Rates & Charges
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabDetails">
                                <div className="row">
                                    <div className="col-md-7">
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Email</td>
                                                <td className="color-black-blue">
                                                    <b>{item.email}</b></td>
                                            </tr>
                                            <tr>
                                                <td>This month's bill</td>
                                                <td className="color-black-blue">
                                                    <b>{item.this_month_string}</b></td>
                                            </tr>
                                            <tr>
                                                <td>This month's requests</td>
                                                <td className="color-black-blue">
                                                    <b>{item.this_months_requests}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Client Status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.active === 0 ? "INACTIVE" : "ACTIVE"}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Admin Status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.admin_active === 0 ? "INACTIVE" : "ACTIVE"}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Registered on</td>
                                                <td className="color-black-blue">
                                                    <b>{item.date_added_string}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabRequests">
                                <DataTable
                                    columns={columns}
                                    data={requestsListOngoing}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    onRowClicked={e => {
                                        history.push('/request/' + e.ID);
                                    }}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabRates">
                                <div className="row">
                                    <div className="col-md-8">
                                        <DataTable
                                            columns={ratesColumns}
                                            data={ratesList}
                                            pointerOnHover={true}
                                            className={"table table-striped table-bordered"}
                                            noHeader={true}
                                            customStyles={Styles.customTableStyles}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Add new rate</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={addRates}>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Select
                                                            request type</label>
                                                        <select className="form-control"
                                                                value={requestType}
                                                                onChange={({target}) => setRequestType(target.value)}>
                                                            {requestTypeList.map((i, k) => <option
                                                                value={i.name}>{i.name}</option>
                                                            )}
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Select
                                                            means type</label>
                                                        <select className="form-control"
                                                                value={meansType}
                                                                onChange={({target}) => setMeansType(target.value)}>
                                                            {meansTypeList.map((i, k) => <option
                                                                value={i.name}>{i.name}</option>
                                                            )}
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Normal rate
                                                            per KM in KES</label>
                                                        <input type="number"
                                                               required={true}
                                                               className="form-control"
                                                               value={normalRate}
                                                               onChange={e => setNormalRate(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Peak rate
                                                            per KM in KES</label>
                                                        <input type="number"
                                                               required={true}
                                                               className="form-control"
                                                               value={peakRate}
                                                               onChange={e => setPeakRate(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Add</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>

                </div>

            </div>
            }
        </AuthProvider>
    )
};


export default AccountDetails