import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Colors from "../../Constants/Colors";
import avatar from "../../Assets/Images/a3.png";
import {Link} from "react-router-dom";

const SideBarNav = props => {

    let d = new Date();

    const [globalState, globalActions] = useGlobal();
    const [currentYear, setCurrentYear] = useState(d.getFullYear())

    return (
        <nav className="side-menu" style={{
            backgroundColor: '#120133',
            padding: '28px 0 0 !important'
        }}>
            <ul className="side-menu-list" style={{margin: '0 0 10px 0'}}>
                <div className="side-menu-avatar" style={{
                    backgroundColor: '#140e33',
                    padding: '5px 0 0 !important'
                }}>
                    <div className="avatar-preview avatar-preview-100">
                        <img src={avatar} alt="" className="userPhoto"/>
                    </div>

                    <div className="text-center p-3" style={{paddingBottom: '0 !important'}}>
                        <h5 className="text-white font-weight-bold"
                            style={{marginBottom: 2}}>{globalState.currentUser.name}</h5>
                        <span style={{color: Colors.white}}>{globalState.currentUser.email}</span>
                    </div>

                </div>

                 <li className="grey">
                    <Link to={"/dashboard"}>
                        <i className="fa fa-home"></i>
                        <span className="lbl">Dashboard</span>
                    </Link>
                </li>

                {/*All requests */}
                <li className="grey">
                    <Link to={"/requests"}>
                        <i className="fa fa-list"></i>
                        <span className="lbl">Requests</span>
                    </Link>
                </li>

                {/*Normal / Corporate */}
                <li className="grey">
                    <Link to={"/clients"}>
                        <i className="font-icon font-icon-users-two"></i>
                        <span className="lbl">Clients</span>
                    </Link>
                </li>

                 <li className="grey">
                    <Link to={"/businesses"}>
                        <i className="font-icon font-icon-users"></i>
                        <span className="lbl">Businesses</span>
                    </Link>
                </li>

                {/*Active / Inactive */}
                <li className="grey">
                    <Link to={"/riders"}>
                        <i className="fa fa-bicycle"></i>
                        <span className="lbl">Riders</span>
                    </Link>
                </li>

                {/*Active / Inactive ==> Ride types, Car types, Services, Rates,  */}
                <li className="grey">
                    <Link to={"/areas"}>
                        <i className="fa fa-map"></i>
                        <span className="lbl">Areas</span>
                    </Link>
                </li>

                <li className="grey">
                    <Link to={"/broadcasts"}>
                        <i className="fa fa-comment"></i>
                        <span className="lbl">Broadcasts</span>
                    </Link>
                </li>


                <li className="grey">
                    <Link to={"/promotions"}>
                        <i className="fa fa-dollar"></i>
                        <span className="lbl">Promotions</span>
                    </Link>
                </li>

                <li className="grey">
                    <a href={"https://pickanddrop.zendesk.com/"} target={"_blank"}>
                        <i className="fa fa-ticket"></i>
                        <span className="lbl">Support (ZenDesk)</span>
                    </a>
                </li>

                <li className="grey">
                    <Link to={"/settings"}>
                        <i className="fa fa-cogs"></i>
                        <span className="lbl">Settings</span>
                    </Link>
                </li>

            </ul>

            <section>
                <header className="side-menu-title" style={{fontSize: 13}}>
                    {`© P&D Admin ${currentYear} v1.0.1`}
                </header>
            </section>
        </nav>
    )
};

export default SideBarNav;
