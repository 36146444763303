import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {handleUserAccess} from "../../Utils/Auth";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import AuthProvider from "../Views/AuthProvider";

const Settings = props => {

    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [settings, setSettings] = useState('');
    const [businessFee, setBusinessFee] = useState('');
    const [returnPerc, setReturnPerc] = useState('');

    useEffect(() => {
        document.title = "Settings | " + Strings.APP_DESCRIPTION;

        if (globalState.userLoggedIn) {
            setName(globalState.currentUser.name);
            setEmailAddress(globalState.currentUser.email);
        }

        loadSettings()

    }, [globalState.userLoggedIn]);

    const submitProfileForm = e => {
        e.preventDefault();

        if (name === '') {
            Notify("Name is required", ERROR);
            return;
        }

        if (!(!!email)) {
            Notify("Email is required", ERROR);
            return;
        }

        let data = new FormData();
        data.append("name", name)
        data.append("email", email)

        setIsLoading(true);

        postData(Endpoints.UPDATE_PROFILE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify("Profile updated successfully", SUCCESS);
                    handleUserAccess(res, globalActions, false);
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    const submitPasswordForm = e => {
        e.preventDefault();

        if (!(!!password)) {
            Notify("Current password is required", ERROR);
            return;
        }

        if (!(!!newPassword)) {
            Notify("New password is required", ERROR);
            return;
        }

        if (newPassword !== confirmPassword) {
            Notify("Please confirm your password", ERROR);
            return;
        }

        let data = new FormData();
        data.append("old_password", password)
        data.append("new_password", newPassword)

        setIsLoading(true);

        postData(Endpoints.UPDATE_PASSWORD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify("Password updated successfully", SUCCESS);
                    handleUserAccess(res, globalActions, false);
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    const submitSettings = e => {
        e.preventDefault();

        if (!(!!businessFee)) {
            Notify("Business fee is required", ERROR);
            return;
        }

        if (!(!!returnPerc)) {
            Notify("Return percentage is required", ERROR);
            return;
        }

        let data = new FormData();
        data.append("business_fee", businessFee)
        data.append("return_percentage", returnPerc)

        setIsLoading(true);

        postData(Endpoints.UPDATE_SETTINGS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify("Site settings updated successfully", SUCCESS);
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    function loadSettings() {
        let data = new FormData();

        setIsLoading(true)
        postData(Endpoints.LIST_SETTINGS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1 && res.data.length > 0) {
                    setBusinessFee(res.data[0].business_fee);
                    setReturnPerc(res.data[0].return_percentage);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Settings
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabSettings" role="tab" data-toggle="tab">
                                        General site settings
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabProfile" role="tab" data-toggle="tab">
                                        Update profile
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabPassword" role="tab" data-toggle="tab">
                                        Change password
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">


                            <div role="tabpanel" className="tab-pane fade in active show" id="tabSettings">
                                <div className="card-block">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Business
                                                        registration fee</label>
                                                    <input type="number"
                                                           className="form-control"
                                                           placeholder="Fee"
                                                           value={businessFee}
                                                           onChange={e => setBusinessFee(e.target.value)}
                                                    />
                                                </div>

                                                {/*<div className="form-group">*/}
                                                {/*    <label className="form-label col-form-label-sm">Return percentage*/}
                                                {/*        (%10)</label>*/}
                                                {/*    <input type="number"*/}
                                                {/*           className="form-control"*/}
                                                {/*           placeholder="Return percentage"*/}
                                                {/*           value={returnPerc}*/}
                                                {/*           onChange={e => setReturnPerc(e.target.value)}*/}
                                                {/*    />*/}
                                                {/*</div>*/}

                                                <div className="form-group">
                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                                onClick={e => submitSettings(e)}>Update site
                                                            settings</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>


                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabProfile">
                                <div className="card-block">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Name</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="Title"
                                                           value={name}
                                                           onChange={e => setName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Email
                                                        address</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="Email address"
                                                           value={email}
                                                           onChange={e => setEmailAddress(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                                onClick={e => submitProfileForm(e)}>Update
                                                            profile</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>


                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabPassword">
                                <div className="card-block">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Current
                                                        password</label>
                                                    <input type="password"
                                                           className="form-control"
                                                           placeholder="Current password"
                                                           value={password}
                                                           onChange={e => setPassword(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">New password</label>
                                                    <input type="password"
                                                           className="form-control"
                                                           placeholder="New password"
                                                           value={newPassword}
                                                           onChange={e => setNewPassword(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Confirm new
                                                        password</label>
                                                    <input type="password"
                                                           className="form-control"
                                                           placeholder="Confirm new password"
                                                           value={confirmPassword}
                                                           onChange={e => setConfirmPassword(e.target.value)}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                                onClick={e => submitPasswordForm(e)}>Change
                                                            password</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>


                                </div>
                            </div>

                        </div>
                    </section>

                </div>

            </div>
        </AuthProvider>
    )
};

export default Settings;

