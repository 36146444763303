const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default {
    SERVER_HOST: IS_DEV ? 'https://pickndropservices.com' : "https://pickndropservices.com",
    WS_HOST: IS_DEV ? 'ws://localhost:5000' : "wss://pickndropservices.com",
    API_URL: '/api/v1/',
    FILE_URL: '/files/',
    WS: 'ws',
    LOGIN: 'admin/login',
    UPDATE_PROFILE: 'admin/update/profile',
    UPDATE_PASSWORD: 'admin/update/password',

    AREA_ADD: 'area/new',
    AREA_LIST: 'area/list',
    AREA_DETAIL: 'area/find',
    AREA_UPDATE_STATUS: 'area/update/status',
    AREA_UPDATE_DETAILS: 'area/update/details',

    REQUEST_TYPE_ADD: 'request_type/new',
    REQUEST_TYPE_LIST: 'request_type/list',
    REQUEST_TYPE_DELETE: 'request_type/delete',
    REQUEST_TYPE_UPDATE_DETAILS: 'request_type/update/details',
    REQUEST_TYPE_UPDATE_STATUS: 'request_type/update/status',
    REQUEST_TYPE_FIND: 'request_type/find',

    MEANS_TYPE_ADD: 'means_type/new',
    MEANS_TYPE_LIST: 'means_type/list',
    MEANS_TYPE_DELETE: 'means_type/delete',
    MEANS_TYPE_UPDATE_DETAILS: 'means_type/update/details',
    MEANS_TYPE_UPDATE_STATUS: 'means_type/update/status',
    MEANS_TYPE_FIND: 'means_type/find',

    PEAK_HOURS_ADD: 'peak_hour/new',
    PEAK_HOURS_LIST: 'peak_hour/list',
    PEAK_HOURS_DELETE: 'peak_hour/delete',

    RATE_ADD: 'rate/new',
    RATE_LIST: 'rate/list',
    RATE_DELETE: 'rate/delete',

    REGION_ADD: 'region/new',
    REGION_LIST: 'region/list',
    REGION_DETAIL: 'region/find',
    REGION_UPDATE_STATUS: 'region/update/status',
    REGION_UPDATE_DETAILS: 'region/update/details',

    RIDER_ADD: 'rider/new',
    RIDER_LIST: 'rider/list',
    RIDER_DETAIL: 'rider/find',
    RIDER_UPDATE_STATUS: 'rider/update/status',
    RIDER_UPDATE_DETAILS: 'rider/update/details',

    REQUEST_LIST: 'request/list-admin',
    REQUEST_LIST_RIDER: 'request/list-rider',
    REQUEST_LIST_USER: 'request/list-user-admin',
    REQUEST_STATS: 'request/list-admin-stats',
    REQUEST_TIMER: 'request/list-admin-timer',
    REQUEST_DETAIL: 'request/find-admin',
    REQUEST_UPDATE_DELIVERY: 'request/update-delivery',
    REQUEST_ASSIGN_RIDER: 'request/assign-rider',
    REQUEST_ASSIGN_REGION: 'request/assign-region',
    REQUEST_CANCEL: 'request/cancel-admin',
    REQUEST_RECEIVE: 'request/cc/receive',

    CLIENT_LIST: 'user/list',
    CLIENT_DETAIL: 'user/find',
    CLIENT_UPDATE_STATUS: 'user/update/status',

    BUSINESS_LIST: 'business/list-admin',
    BUSINESS_DETAIL: 'business/find-admin',
    BUSINESS_UPDATE_STATUS: 'business/update/status-admin',

    DEPARTMENT_LIST: 'department/list-admin',
    DEPARTMENT_DETAIL: 'department/find-admin',
    DEPARTMENT_UPDATE_STATUS: 'department/update/status-admin',

    CHILD_ACCOUNT_LIST: 'child-account/list-admin',
    CHILD_ACCOUNT_DETAIL: 'child-account/find-admin',
    CHILD_ACCOUNT_UPDATE_STATUS: 'child-account/update/status-admin',

    GET_STATS: 'stats',

    SEND_BROADCAST: 'broadcast/new',
    LIST_BROADCAST: 'broadcast/list',
    LIST_SCHEDULED_BROADCAST: 'broadcast/scheduled',
    DELETE_BROADCAST: 'broadcast/delete',

    LIST_SETTINGS: 'settings/list',
    UPDATE_SETTINGS: 'settings/update',

    NEW_PROMO: 'promo/new',
    LIST_PROMO: 'promo/list',
    DELETE_PROMO: 'promo/delete',

};
