import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {Link} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import AuthProvider from "../Views/AuthProvider";
import {goToPage, updateDocumentTitle} from "../../Utils/Common";
import {useHistory} from "react-router-dom";

const Search = props => {

    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState('');
    const [results, setResults] = useState('');

    useEffect(() => {
        updateDocumentTitle("Search")
    }, []);

    const searchItems = e => {
        e.preventDefault();

        let data = new FormData();
        data.append("code", code)

        setIsLoading(true);

        postData(Endpoints.REQUEST_RECEIVE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setCode('');
                    Notify(res.data, SUCCESS);
                    goToPage(history, "/requests/cc")
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Search for requests, clients, riders,
                                                        businesses etc.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabNew" role="tab" data-toggle="tab">
                                        Search phrase here
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabNew">

                                <div className="card-block">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <input type="text"
                                                           required={true}
                                                           className="form-control"
                                                           placeholder="Type here..."
                                                           value={code}
                                                           onChange={e => setCode(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                                onClick={e => searchItems(e)}>Search</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>
                                </div>

                                {!isLoading &&
                                <section className="tabs-section">
                                    <div className="tabs-section-nav tabs-section-nav-inline">
                                        <ul className="nav" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" href="#tabRequests" role="tab"
                                                   data-toggle="tab">
                                                    Requests
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#tabClients" role="tab"
                                                   data-toggle="tab">
                                                    Clients
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#tabRiders" role="tab"
                                                   data-toggle="tab">
                                                    Riders
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#tabBusinesses" role="tab"
                                                   data-toggle="tab">
                                                    Businesses
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane fade in active show" id="tabRequests">

                                        </div>

                                    </div>
                                </section>
                                }

                            </div>


                        </div>
                    </section>


                </div>

            </div>
        </AuthProvider>
    )
};

export default Search;

