import React, {useEffect, useState} from "react";
import {GoogleMap, LoadScript, Circle, Marker} from '@react-google-maps/api';
import Strings from "../../Constants/Strings";
import {useHistory} from "react-router-dom";

const Map = props => {
    let history = useHistory();

    const isMultipleMarkers = props.isMultiple || false
    const markers = props.markers || []

    const options = {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        zIndex: 1
    }

    const containerStyle = {
        width: '100%',
        height: isMultipleMarkers ? 1000 : 500
    }

    return (
        <LoadScriptOnlyIfNeeded googleMapsApiKey={Strings.GOOGLE_API_KEY} id={1}>
            <GoogleMap
                zoom={10}
                center={props.mapCentre}
                mapContainerStyle={containerStyle}
            >

                {!isMultipleMarkers &&
                <Marker
                    position={props.mapCentre}
                />
                }

                {markers.map((i, k) => <Marker
                    title={i.name + " - " + i.ongoing_jobs + " ongoing jobs"}
                    onClick={e => history.push('/riders/' + i.ID)}
                    position={{
                        lat: i.current_lat,
                        lng: i.current_lng
                    }}
                />)
                }

                {props.showCircle &&
                <Circle
                    radius={props.radius * 1000}
                    center={props.mapCenter}
                    options={options}
                />
                }

            </GoogleMap>
        </LoadScriptOnlyIfNeeded>)
}


export default Map

class LoadScriptOnlyIfNeeded extends LoadScript {
    componentDidMount() {
        const cleaningUp = true;
        const isBrowser = typeof document !== 'undefined'; // require('@react-google-maps/api/src/utils/isbrowser')
        const isAlreadyLoaded =
            window.google &&
            window.google.maps &&
            document.querySelector('body.first-hit-completed'); // AJAX page loading system is adding this class the first time the app is loaded
        if (!isAlreadyLoaded && isBrowser) {
            // @ts-ignore
            if (window.google && !cleaningUp) {
                console.error('google api is already presented');
                return;
            }

            this.isCleaningUp().then(this.injectScript);
        }

        if (isAlreadyLoaded) {
            this.setState({loaded: true});
        }
    }
}