import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {Link} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import AuthProvider from "../Views/AuthProvider";
import {goToPage, updateDocumentTitle} from "../../Utils/Common";
import {useHistory} from "react-router-dom";
import Map from "../Map/Map";

const LiveMap = props => {

    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [activeList, setActiveList] = useState([]);
    const [mapCentre, setMapCentre] = useState('');

    useEffect(() => {
        updateDocumentTitle("Live map")
        load(false)
        setInterval(() => {
            load(false)
        }, 15000)
    }, []);


    const load = loadMore => {
        let data = new FormData();
        data.append("status", 1);
        setIsLoading(true);
        postData(Endpoints.RIDER_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? activeList : [];
                currentPosts = currentPosts.concat(...res.data);
                setActiveList(currentPosts);
                if (currentPosts.length > 0) {
                    setMapCentre({
                        lat: currentPosts[0].current_lat,
                        lng: currentPosts[0].current_lng
                    })
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };


    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Live map
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                 <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-center">
                                                    {isLoading &&
                                                    <ProgressView size={23} style={{color: Colors.black, margin: 10}}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabNew" role="tab" data-toggle="tab">
                                        See where each rider is from a single point
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabNew">
                                <Map showCircle={false} isMultiple={true} markers={activeList} mapCentre={mapCentre}/>
                            </div>
                        </div>
                    </section>

                </div>

            </div>
        </AuthProvider>
    )
};

export default LiveMap;

