import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {updateDocumentTitle} from "../../Utils/Common";
import Map from "../Map/Map";

const RequestTypeDetails = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({title: ''});
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [requestTypeTime, setRequestTypeTime] = useState('');
    const [requestTypeStatus, setRequestTypeStatus] = useState(1);

    const {id} = props.match.params;

    const load = () => {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true);
        postData(Endpoints.REQUEST_TYPE_FIND, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setItem(res.data);
                    setName(res.data.name);
                    setDescription(res.data.description);
                    setRequestTypeStatus(res.data.can_be_handed_over ? 1 : 0)
                    setRequestTypeTime(res.data.expected_time)
                    updateDocumentTitle(res.data.name)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        load()
    }, []);

    function updateStatus(number) {
        let data = new FormData();
        data.append("id", id);
        data.append("status", number);

        setIsLoading(true);
        postData(Endpoints.REQUEST_TYPE_UPDATE_STATUS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function updateDetails(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("id", id)
        data.append("name", name)
        data.append("description", description)
        data.append("expected_time", requestTypeTime)
        data.append("status", requestTypeStatus)

        setIsLoading(true)
        postData(Endpoints.REQUEST_TYPE_UPDATE_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function deleteMeansType() {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true)
        postData(Endpoints.MEANS_TYPE_DELETE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.data, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    return (
        <AuthProvider>
            {item.title !== '' &&
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>{item.name}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-center">
                                                    {isLoading &&
                                                    <ProgressView size={23} style={{color: Colors.black, margin: 10}}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    {!isLoading &&
                                                    <button type="submit"
                                                            className={item.status === 1 ? "btn btn-danger" : "btn btn-success"}
                                                            onClick={e => updateStatus(item.status === 1 ? 0 : 1)}>{item.status === 1 ? "Deactivate" : "Activate"}</button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to={"/areas/" + item.area_id}
                                                          className="btn btn-success-outline">
                                                        Back to area details
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabDetails" role="tab" data-toggle="tab">
                                        Details
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabDetails">
                                <div className="row">
                                    <div className="col-md-6">
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td className="color-black-blue">
                                                    <b>{item.name}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Description</td>
                                                <td className="color-black-blue">
                                                    <b>{item.description}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Expected Time</td>
                                                <td className="color-black-blue">
                                                    <b>{item.expected_time}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Mode</td>
                                                <td className="color-black-blue">
                                                    <b>{item.can_be_handed_over ? "Standard" : "Express"}</b></td>
                                            </tr>

                                            <tr>
                                                <td>Status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.status === 0 ? "INACTIVE" : "ACTIVE"}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>


                                    </div>
                                    <div className="col-md-6">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Update</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={updateDetails}>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Name</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={name}
                                                               onChange={e => setName(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Description</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Description"
                                                               value={description}
                                                               onChange={e => setDescription(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Expected duration
                                                            in hours</label>
                                                        <input type="number"
                                                               required={true}
                                                               className="form-control"
                                                               value={requestTypeTime}
                                                               onChange={e => setRequestTypeTime(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Select a
                                                            mode</label>
                                                        <select className="form-control"
                                                                value={requestTypeStatus}
                                                                onChange={({target}) => setRequestTypeStatus(target.value)}>
                                                            <option
                                                                value={1}>Standard mode - Riders can hand over requests
                                                                to each other
                                                            </option>
                                                            <option
                                                                value={0}>Express mode - Only one rider is allowed to
                                                                handle each
                                                                request
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Update</button>
                                                        }
                                                    </div>

                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>
                </div>

            </div>
            }
        </AuthProvider>
    )
};


export default RequestTypeDetails