import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {Link} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import AuthProvider from "../Views/AuthProvider";
import {goToPage, updateDocumentTitle} from "../../Utils/Common";
import {useHistory} from "react-router-dom";

const RequestOverview = props => {

    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [stats, setStats] = useState('');
    const [lastUpdated, setLastUpdated] = useState('');
    let lastLoaded = new Date();

    useEffect(() => {
        updateDocumentTitle("Requests overview")
        loadCounter()

        setInterval(() => {
            loadCounter()
        }, 60000)
    }, []);

    function loadCounter() {
        getTimeDifference()
        let data = new FormData();
        data.append("status", 1);
        setIsLoading(true)
        postData(Endpoints.REQUEST_STATS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setStats(res.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const getTimeDifference = () => {
        console.log("last loaded ", lastLoaded)
        let now = new Date();
        let diffMs = (now - lastLoaded);
        let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
        lastLoaded = new Date()
        setLastUpdated(`${diffMins} mins ago`)
    }


    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Requests overview
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <h6 style={{fontWeight: 600, color: Colors.red_600}}>Last
                                                    updated {lastUpdated}</h6>

                                                {isLoading &&
                                                <ProgressView size={23}
                                                              style={{color: Colors.black, marginTop: 5}}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <button onClick={e=>loadCounter()}
                                                            className="btn btn-success">
                                                        Reload
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="row">

                                <div className="col-sm-4">
                                    <Link to={"/requests/incoming"}>
                                        <article className="statistic-box red">
                                            <div>
                                                <div className="number">{stats.incoming}</div>
                                                <div className="caption">
                                                    <div>Incoming / new</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-4">
                                    <Link to={"/requests/assigned"}>
                                        <article className="statistic-box red">
                                            <div>
                                                <div className="number">{stats.assigned}</div>
                                                <div className="caption">
                                                    <div>Assigned & not started</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-4">
                                    <Link to={"/requests/ongoing"}>
                                        <article className="statistic-box green">
                                            <div>
                                                <div className="number">{stats.ongoing}</div>
                                                <div className="caption">
                                                    <div>Ongoing</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-2">
                                    <Link to={"/requests/rone"}>
                                        <article className="statistic-box bg-dark text-white">
                                            <div>
                                                <div className="number">{stats.rone}</div>
                                                <div className="caption">
                                                    <div>At R1</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-2">
                                    <Link to={"/requests/rtwo"}>
                                        <article className="statistic-box bg-dark text-white">
                                            <div>
                                                <div className="number">{stats.rtwo}</div>
                                                <div className="caption">
                                                    <div>At R2</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-2">
                                    <Link to={"/requests/cc"}>
                                        <article className="statistic-box bg-dark text-white">
                                            <div>
                                                <div className="number">{stats.cc}</div>
                                                <div className="caption">
                                                    <div>At CC</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-2">
                                    <Link to={"/requests/rthree"}>
                                        <article className="statistic-box bg-dark text-white">
                                            <div>
                                                <div className="number">{stats.rthree}</div>
                                                <div className="caption">
                                                    <div>At R3</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-2">
                                    <Link to={"/requests/rfour"}>
                                        <article className="statistic-box bg-dark text-white">
                                            <div>
                                                <div className="number">{stats.rfour}</div>
                                                <div className="caption">
                                                    <div>At R4</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Link to={"/requests/late"}>
                                        <article className="statistic-box bg-danger text-white">
                                            <div>
                                                <div className="number">{stats.late}</div>
                                                <div className="caption">
                                                    <div>Late</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-4">
                                    <Link to={"/requests/cancelled"}>
                                        <article className="statistic-box red">
                                            <div>
                                                <div className="number">{stats.cancelled}</div>
                                                <div className="caption">
                                                    <div>Cancelled</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-4">
                                    <Link to={"/requests/completed"}>
                                        <article className="statistic-box green">
                                            <div>
                                                <div className="number">{stats.completed}</div>
                                                <div className="caption">
                                                    <div>Completed</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-4">
                                    <Link to={"/requests/bounced"}>
                                        <article className="statistic-box purple">
                                            <div>
                                                <div className="number">{stats.bounced}</div>
                                                <div className="caption">
                                                    <div>Bounced</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </AuthProvider>
    )
};

export default RequestOverview;

