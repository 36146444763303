import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import logo from "../../Assets/Images/logo.png"
import Colors from "../../Constants/Colors";
import {Link} from "react-router-dom";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import ProgressView from "../Views/ProgressView";

const Login = props => {

    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        document.title = "Log in | " + Strings.APP_DESCRIPTION;
        if (globalState.userLoggedIn) {
            window.location.assign(process.env.PUBLIC_URL + "/dashboard");
        }
    }, [globalState.userLoggedIn]);

    const logIn = (e) => {
        e.preventDefault();
        let data = new FormData();
        data.append("email", email)
        data.append("password", password)

        setIsLoading(true);

        postData(Endpoints.LOGIN, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    handleUserAccess(res, globalActions, true);
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }


    return (
        <div className="page-content" style={{height: '100vh', backgroundColor: Colors.white, verticalAlign: 'middle'}}>
            <div className="row" style={{height: '100%', verticalAlign: 'middle'}}>
                <div className="col-md-8" style={{height: '100%', verticalAlign: 'middle'}}>
                    <div style={{textAlign: 'center'}}>
                        <img src={logo} alt="" style={{height: 100, marginBottom: 15}}/>
                        <h2>PICK&DROP ADMIN AREA</h2>
                        <p>Manage all Pick&Drop services</p>
                    </div>
                </div>
                <div className="col-md-4 text-center" style={{height: '100%', verticalAlign: 'middle'}}>
                    <form className="sign-box" id="loginForm">
                        <header className="sign-title">Sign In</header>
                        <div className="form-group">
                            <input type="text"
                                   id="loginEmail" required
                                   onChange={e => setEmail(e.target.value)}
                                   className="form-control"
                                   placeholder="E-mail"
                                   value={email}/>
                        </div>
                        <div className="form-group">
                            <input type="password" id="loginPassword" required className="form-control"
                                   placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        {isLoading ?
                            <ProgressView size={23} style={{color: Colors.black, marginTop: 5}}/> :
                            <button type="submit" className="btn btn-rounded" onClick={e => logIn(e)}>Sign in</button>
                        }
                    </form>

                </div>
            </div>
        </div>
    )
};

export default Login;
