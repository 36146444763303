import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {Link} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import AuthProvider from "../Views/AuthProvider";
import {goToPage, updateDocumentTitle} from "../../Utils/Common";
import {useHistory} from "react-router-dom";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import DatePicker from "../Views/DatePicker";
import TimePicker from "../Views/TimePicker";

const Promo = props => {

    let d = new Date();

    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [activeList, setActiveList] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const [inactiveList, setInactiveList] = useState([]);

    const [promoCode, setPromoCode] = useState('');
    const [audience, setAudience] = useState('any_user');
    const [business, setBusiness] = useState(0);
    const [promoType, setPromoType] = useState('percentage');
    const [promoMinAmount, setPromoMinAmount] = useState('');
    const [promoValue, setPromoValue] = useState('');
    const [promoBudget, setPromoBudget] = useState('');

    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');


    const columns = [
        {
            name: 'Promo code',
            selector: 'promo_code',
            cell: row => <div>
                <p>Code : {row.promo_code}</p>
                <p>Budget : {row.budget}</p>
                <p>Usage : {row.usage}</p>
            </div>,
        },
        {
            name: 'Audience',
            selector: 'audience',
        },
        {
            name: 'Business',
            cell: row => <div><span>{row.business_details.name}</span></div>,
        },
        {
            name: 'Start',
            cell: row => <div><span>{row.start_date_string}</span></div>,
        },
        {
            name: 'End',
            cell: row => <div><span>{row.end_date_string}</span></div>,
        },
        {
            name: 'Status',
            cell: row => <div><span
                style={{color: row.status === 1 ? Colors.green_700 : Colors.red_700}}>{row.status === 1 ? "ACTIVE" : "INACTIVE"}</span>
            </div>,
        },
        {
            name: '',
            cell: row => row.status === 0 ? '-' : <button className={"btn btn-sm btn-danger"}
                                                          onClick={e => deletePromo(row.ID)}>Delete</button>,
        },
    ]


    useEffect(() => {
        updateDocumentTitle("Promotions")
        loadBusinesses()
        loadHistory(1)
        loadHistory(0)
    }, []);

    const loadBusinesses = () => {
        let data = new FormData();
        data.append("status", 1);
        setIsLoading(true);
        postData(Endpoints.BUSINESS_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                setBusinessList(res.data || []);
                if (res.data.length > 0) {
                    setBusiness(res.data[0].ID)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    function deletePromo(id) {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true)
        postData(Endpoints.DELETE_PROMO, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    loadHistory(1)
                    loadHistory(0)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const submitForm = e => {
        e.preventDefault();

        let data = new FormData();
        data.append("promo_code", promoCode)
        data.append("audience", audience)
        data.append("business_id", business)
        data.append("promo_type", promoType)
        data.append("promo_min_amount", promoMinAmount)
        data.append("promo_value", promoValue)
        data.append("promo_budget", promoBudget)
        data.append("start_date", startDate)
        data.append("start_time", startTime)
        data.append("end_date", endDate)
        data.append("end_time", endTime)

        setIsLoading(true);

        postData(Endpoints.NEW_PROMO, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setPromoCode('');
                    setPromoValue('');
                    loadHistory(1)
                    Notify(res.data, SUCCESS);
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    function loadHistory(status) {
        let data = new FormData();
        data.append("status", status);
        setIsLoading(true)
        postData(Endpoints.LIST_PROMO, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    if (status === 1) {
                        setActiveList(res.data || []);
                    } else {
                        setInactiveList(res.data || []);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Promotions
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabNew" role="tab" data-toggle="tab">
                                        Create a new promotion
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabActive" role="tab" data-toggle="tab">
                                        Active promotions
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabInactive" role="tab" data-toggle="tab">
                                        Inactive promotions
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabNew">

                                <div className="card-block">
                                    <form onSubmit={submitForm}>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Promo code</label>
                                                    <input type="text"
                                                           placeholder={"Promo code"}
                                                           className="form-control"
                                                           value={promoCode}
                                                           onChange={e => setPromoCode(e.target.value)}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Audience</label>
                                                    <select className="form-control"
                                                            value={audience}
                                                            onChange={({target}) => setAudience(target.value)}>
                                                        <option value={"any_user"}>Any user</option>
                                                        <option value={"corporate_user"}>Only corporate users</option>
                                                        <option value={"specific_business"}>Only specific business
                                                        </option>
                                                    </select>
                                                </div>

                                                {audience === "specific_business" &&
                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Select a
                                                        business</label>
                                                    <select className="form-control"
                                                            value={business}
                                                            onChange={({target}) => setBusiness(target.value)}>
                                                        {businessList.map((i) => <option
                                                            value={i.ID}>{i.name}</option>)}

                                                    </select>
                                                </div>
                                                }

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Promo type</label>
                                                    <select className="form-control"
                                                            value={promoType}
                                                            onChange={({target}) => setPromoType(target.value)}>
                                                        <option value={"percentage"}>Percentage discount</option>
                                                        <option value={"fixed"}>Fixed amount discount</option>
                                                    </select>
                                                </div>


                                                <div className="form-group">
                                                    <label
                                                        className="form-label col-form-label-sm">Promo {promoType === "fixed" ? "fixed discount value" : "percentage value"}</label>
                                                    <input type="number"
                                                           placeholder={"Value"}
                                                           className="form-control"
                                                           value={promoValue}
                                                           onChange={e => setPromoValue(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Minimum amount
                                                        required</label>
                                                    <input type="number"
                                                           placeholder={"Min amount"}
                                                           className="form-control"
                                                           value={promoMinAmount}
                                                           onChange={e => setPromoMinAmount(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Budget (Promo will
                                                        deactivate itself when usage value reaches this budget)</label>
                                                    <input type="number"
                                                           placeholder={"Min amount"}
                                                           className="form-control"
                                                           value={promoBudget}
                                                           onChange={e => setPromoBudget(e.target.value)}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Start date &
                                                        time</label>
                                                    <div>
                                                        <DatePicker
                                                            onDateSelected={(pickerDate, pickerMonth, pickerYear) => {
                                                                setStartDate(`${pickerYear}-${pickerMonth}-${pickerDate}`)
                                                            }}/>

                                                        <TimePicker onTimeSelected={(pickerTime, pickerMinute) => {
                                                            console.log(pickerTime, pickerMinute)
                                                            setStartTime(`${pickerTime}:${pickerMinute}`)
                                                        }}/>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">End date &
                                                        time</label>
                                                    <div>
                                                        <DatePicker
                                                            onDateSelected={(pickerDate, pickerMonth, pickerYear) => {
                                                                setEndDate(`${pickerYear}-${pickerMonth}-${pickerDate}`)
                                                            }}/>

                                                        <TimePicker onTimeSelected={(pickerTime, pickerMinute) => {
                                                            console.log(pickerTime, pickerMinute)
                                                            setEndTime(`${pickerTime}:${pickerMinute}`)
                                                        }}/>
                                                    </div>
                                                </div>

                                                <div className="form-group">

                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                        >Add promo</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>
                                </div>

                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabActive">

                                <DataTable
                                    columns={columns}
                                    data={activeList}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabInactive">

                                <DataTable
                                    columns={columns}
                                    data={inactiveList}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>

                        </div>
                    </section>

                </div>

            </div>
        </AuthProvider>
    )
};

export default Promo;

