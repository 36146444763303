import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {Link} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import AuthProvider from "../Views/AuthProvider";
import {goToPage, updateDocumentTitle} from "../../Utils/Common";
import {useHistory} from "react-router-dom";

const NewRider = props => {

    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [idNumber, setIDNumber] = useState('');
    const [vehicleRegNo, setVehicleRegNo] = useState('');
    const [areaID, setAreaID] = useState('');
    const [regionID, setRegionID] = useState('');
    const [regionList, setRegionList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    useEffect(() => {
        updateDocumentTitle("Add new rider")
        loadAreas()
    }, []);

    const submitForm = e => {
        e.preventDefault();

        let data = new FormData();
        data.append("name", name)
        data.append("email", email)
        data.append("phone", phone)
        data.append("id_number", idNumber)
        data.append("reg_no", vehicleRegNo)
        data.append("area_id", areaID)
        data.append("region_id", regionID)

        setIsLoading(true);

        postData(Endpoints.RIDER_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setName('');
                    Notify(res.data, SUCCESS);
                    goToPage(history, "/riders")
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    function loadAreas() {
        let data = new FormData();
        data.append("status", 1);
        setIsLoading(true)
        postData(Endpoints.AREA_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setAreaList(res.data || []);
                    if (res.data.length > 0) {
                        setAreaID(res.data[0].ID)
                        loadRegions(res.data[0].ID)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadRegions(id) {
        let data = new FormData();
        data.append("area_id", id);
        data.append("status", 1);
        setIsLoading(true)
        postData(Endpoints.REGION_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRegionList(res.data || []);
                    if (res.data.length > 0) setRegionID(res.data[0].ID)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Add a new rider
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to="/riders"
                                                          className="btn btn-success-outline">
                                                        Back to riders
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabNew" role="tab" data-toggle="tab">
                                        Enter new rider details
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabNew">

                                <div className="card-block">
                                    <form onSubmit={submitForm}>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Full name</label>
                                                    <input type="text"
                                                           required
                                                           className="form-control"
                                                           value={name}
                                                           onChange={e => setName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Email</label>
                                                    <input type="text"
                                                           required
                                                           className="form-control"
                                                           value={email}
                                                           onChange={e => setEmail(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Phone number</label>
                                                    <input type="text"
                                                           required
                                                           className="form-control"
                                                           value={phone}
                                                           onChange={e => setPhone(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Vehicle registration
                                                        number</label>
                                                    <input type="text"
                                                           required
                                                           className="form-control"
                                                           value={vehicleRegNo}
                                                           onChange={e => setVehicleRegNo(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label
                                                        className="form-label col-form-label-sm">Select
                                                        area</label>
                                                    <select className="form-control"
                                                            value={areaID}
                                                            onChange={({target}) => {
                                                                setAreaID(target.value)
                                                                loadRegions(target.value)
                                                            }}>
                                                        {areaList.map((i, k) => <option
                                                            value={i.ID}>{i.title}</option>
                                                        )}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label
                                                        className="form-label col-form-label-sm">Select
                                                        region</label>
                                                    <select className="form-control"
                                                            value={regionID}
                                                            onChange={({target}) => setRegionID(target.value)}>
                                                        {regionList.map((i, k) => <option
                                                            value={i.ID}>{i.title}</option>
                                                        )}
                                                    </select>
                                                </div>


                                                <div className="form-group">
                                                    <p><small>*They will receive their login PIN via SMS</small></p>

                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                        >Create</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>
                                </div>

                            </div>


                        </div>
                    </section>

                </div>

            </div>
        </AuthProvider>
    )
};

export default NewRider;

